<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">
          
        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <!-- <template #actionModals>
        <div></div>
      </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      module_name: "notifications", //vuex module name which define in Store Index
      permissions: {
        view: "notifications.view",
        create: "notifications.create",
        edit: "notifications.edit",
        delete: "notifications.delete",
      },
      //
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "notifications/getLoading",
      meta: "notifications/getMeta",
      list: "notifications/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "monitoring_alert_id",
        },
        {
          text: this.$t("vehicle_name"),
          align: "left",
          sortable: false,
          value: "vehicle_name",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        ...(this.authUser.user_nature == "fleet"
          ? [
              {
                text: this.$t("manufacturer"),
                align: "left",
                sortable: false,
                value: "tenant_name",
              },
            ]
          : []),
        {
          text: this.$t("message"),
          align: "left",
          sortable: false,
          value: "message",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },
        // {
        //   text: this.$t("event_date"),
        //   align: "left",
        //   sortable: false,
        //   value: "event_date",
        // },
      ];

      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return ["search"];
      // if (this.authUser.isAdmin) {
      // } else {
      //   return [];
      // }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "notifications",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = { ...this.options };
      params.status = params.status
        ? params.status == "active"
          ? "1"
          : "0"
        : null;
      await this.$store.dispatch("notifications/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },
  },
};
</script>
